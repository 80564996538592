import React, { useEffect, useState } from 'react';
import { cn } from "@/utils/utils";
import { useSearchParams } from 'react-router-dom';
import ManageKeywords from './ManageKeywords';
import GoogleAdsBudgetPage from './ManageBudgetPage';
import GoogleAdsLocationPage from './ManageLocationsPage';
import SettingsPage from './SettingsPage';
import ManageAdContent from './ManageAdContent';
import { Card } from '@/components/ui/card';
import AnalyticsDashboard from '@/components/AnalyticsDashboard';
import OnboardingOverlay from './overlay/OnboardingOverlay';
import PageSkeletons from '@/components/ui/skeleton-loader';
import { navigationItems, NavigationItem } from '@/types/navigation';
import { useCampaign } from '@/contexts/CampaignContext';

const ManageCampaignPage: React.FC = () => {

  const {
    campaignStatusLoading,
    homepageData,
    fetchHomepageDataIfNeeded,
    homepageDataLoading,
    fetchKeywordsIfNeeded,
    keywordsLoading,
    fetchBudgetIfNeeded,
    budgetLoading,
    headlines,
    descriptions,
    domain,
    fetchPreviewIfNeeded,
    previewLoading,
    campaignStatus,
    fetchCampaignStatusIfNeeded,
    fetchLocationDataIfNeeded,
    locationDataLoading,
    fetchAdCreativeIfNeeded,
    adCreativeLoading,
    handleFetchCampaignsIfNeeded,
    showOnboarding,
    setShowOnboarding
  } = useCampaign();

  const [selectedNav, setSelectedNav] = useState<NavigationItem>('overview');
  const [currentAdIndex, setCurrentAdIndex] = useState(0);

  const [searchParams, setSearchParams] = useSearchParams();
  const isOverlayOpen = searchParams.get('overlayOpen') === '1';

  const handleCloseOverlay = () => {
    searchParams.delete('overlayOpen');
    searchParams.delete('onboardingStep');
    setSearchParams(searchParams);
    setShowOnboarding(false);
  };

  useEffect(() => {
    if (showOnboarding) {
      const newParams = new URLSearchParams(searchParams);
      // Only set initial params if they don't already exist
      if (!searchParams.has('overlayOpen') || !searchParams.has('onboardingStep')) {
        console.log("Initializing onboarding params");
        newParams.set('overlayOpen', '1');
        newParams.set('onboardingStep', '0');
        setSearchParams(newParams);
      }
    }
  }, [showOnboarding]);

  // Initialize page data
  useEffect(() => {
    const initializePage = async () => {
      switch (selectedNav) {
        case 'overview':
          await Promise.all([
            fetchHomepageDataIfNeeded(),
            fetchPreviewIfNeeded(),
          ]);
          break;
        case 'keywords':
          await fetchKeywordsIfNeeded();
          break;
        case 'budget':
          await fetchBudgetIfNeeded();
          break;
        case 'locationTargeting':
          await fetchLocationDataIfNeeded();
          break;
        case 'adContent':
          await fetchAdCreativeIfNeeded();
          break;
      }
      await handleFetchCampaignsIfNeeded();
    };

    initializePage();
  }, [selectedNav]);

  const isLoading = () => {
    switch (selectedNav) {
      case 'overview':
        return homepageDataLoading || previewLoading || campaignStatusLoading;
      case 'keywords':
        return keywordsLoading;
      case 'budget':
        return budgetLoading;
      case 'locationTargeting':
        return locationDataLoading;
      case 'adContent':
        return adCreativeLoading;
      default:
        return false;
    }
  };

  const handleAdNavigation = (direction: 'next' | 'prev') => {
    if (direction === 'next') {
      setCurrentAdIndex(prev => 
        prev < (headlines.length || 1) - 1 ? prev + 1 : 0
      );
    } else {
      setCurrentAdIndex(prev => 
        prev > 0 ? prev - 1 : (headlines.length || 1) - 1
      );
    }
  };

  const renderAnalyticsContent = () => {
    if (!homepageData) return null;

    console.log('Homepage Data in Parent:', homepageData);
    console.log('Homepage Data Structure:', JSON.stringify(homepageData, null, 2));
    
    return (
      <div className="space-y-6">
        <AnalyticsDashboard 
          data={homepageData}
          adPreviews={{
            headlines,
            descriptions,
            domain,
            currentIndex: currentAdIndex,
            onNext: () => handleAdNavigation('next'),
            onPrevious: () => handleAdNavigation('prev')
          }}
        /> 
        <h2 className="text-xl font-semibold mb-4">Campaign overview</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <Card className="p-6">
            <h3 className="text-lg font-semibold mb-2">Ad previews</h3>
            <div className="border p-4 rounded">
              {(!headlines.length || !descriptions.length || !domain) ? (
                <p className="text-gray-500">No ad creative found</p>
              ) : (
                <div className="space-y-4">
                  <p className="text-sm text-gray-500">Ad {currentAdIndex + 1} of {headlines.length}</p>
                  <div>
                    <p className="text-sm text-green-600">{domain}</p>
                    <h4 className="text-blue-600 font-medium">
                      {headlines[currentAdIndex]}
                    </h4>
                    <p className="text-sm text-gray-600">
                      {descriptions[currentAdIndex]}
                    </p>
                  </div>
                  <div className="flex justify-between mt-4">
                    <button
                      onClick={() => handleAdNavigation('prev')}
                      className="px-3 py-1 text-sm border rounded hover:bg-gray-50 transition-colors"
                      disabled={headlines.length <= 1}
                    >
                      Previous
                    </button>
                    <button
                      onClick={() => handleAdNavigation('next')}
                      className="px-3 py-1 text-sm border rounded hover:bg-gray-50 transition-colors"
                      disabled={headlines.length <= 1}
                    >
                      Next
                    </button>
                  </div>
                </div>
              )}
            </div>
          </Card>
  
          <Card className="p-6">
            <h3 className="text-lg font-semibold mb-2">Ad search results</h3>
            <div className="border p-4 rounded h-full flex items-center justify-center">
              <div className="text-center">
                <p className="text-gray-500 mb-2">No search terms data available yet</p>
                <p className="text-sm text-gray-400">Search terms will appear here once your ads start receiving impressions</p>
              </div>
            </div>
          </Card>
        </div>
      </div>
    );
  };

  const SideNavigation = () => (
    <nav className="w-64 bg-white border-r">
      <ul className="py-4">
        {navigationItems.map((item) => (
          <li key={item.id}>
            <button
              onClick={() => setSelectedNav(item.id)}
              className={cn(
                "w-full text-left px-4 py-2 flex items-center space-x-2",
                selectedNav === item.id ? "bg-blue-100 text-blue-600" : "hover:bg-gray-100"
              )}
            >
              <span>{item.icon}</span>
              <span>{item.label}</span>
            </button>
          </li>
        ))}
      </ul>
    </nav>
  );

  if (isLoading()) {
    return (
      <div className="flex h-full">
        <SideNavigation />
        <div className="flex-1 p-4 md:p-8">
          <PageSkeletons selectedNav={selectedNav} />
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="flex h-full">
        <SideNavigation />
        <div className="flex-1 p-4 md:p-8">
          {selectedNav === 'keywords' ? (
            <ManageKeywords />
          ) : selectedNav === 'adContent' ? (
            <ManageAdContent />
          ) : selectedNav === 'budget' ? (
            <GoogleAdsBudgetPage />
          ) : selectedNav === 'locationTargeting' ? (
            <GoogleAdsLocationPage />
          ) : selectedNav === 'settings' ? (
            <SettingsPage />
          ) : (
            renderAnalyticsContent()
          )}
        </div>
      </div>

      <OnboardingOverlay 
        isOpen={isOverlayOpen}
        onClose={handleCloseOverlay}
        domain={domain}
      />
    </>
  );
};

export default ManageCampaignPage;