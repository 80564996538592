import React from 'react';
import { Card } from '@/components/ui/card';
import { ArrowUpRight, ArrowDownRight, MousePointer, Eye, DollarSign, Target } from 'lucide-react';
import { useCampaign } from '@/contexts/CampaignContext';
import { HomepageData } from '@/pages/page_types/homepage';

interface AdPreviewsData {
  headlines: string[];
  descriptions: string[];
  domain: string;
  currentIndex: number;
  onNext: () => void;
  onPrevious: () => void;
}

interface AnalyticsDashboardProps {
  data: HomepageData;
  adPreviews: AdPreviewsData;
}

const formatNumber = (num: number | null): string => {
  if (num === null || isNaN(num)) return '0';
  return new Intl.NumberFormat().format(num);
};

const formatCurrency = (microCents: number | null): string => {
  if (microCents === null || isNaN(microCents)) return '$0.00';
  const dollars = microCents / 1000000;
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(dollars);
};

const formatPercentage = (value: number | null): string => {
  if (value === null || isNaN(value)) return '0.00%';
  return `${(value * 100).toFixed(2)}%`;
};

const hasData = (value: number | null): boolean => {
  return value !== null && !isNaN(value) && value > 0;
};

const AnalyticsDashboard: React.FC<AnalyticsDashboardProps> = ({ 
  data, 
  adPreviews 
}) => {
  const {
    toggleCampaignStatus
  } = useCampaign();

  const metrics = [
    {
      title: "Impressions",
      value: formatNumber(data.impressions),
      icon: Eye,
      detail: hasData(data.impressions) 
        ? `${formatNumber(data.impressions)} views`
        : "Not enough data yet...",
      color: "text-blue-600"
    },
    {
      title: "Clicks",
      value: formatNumber(data.clicks),
      icon: MousePointer,
      detail: hasData(data.clicks)
        ? `${formatNumber(data.clicks)} clicks`
        : "Not enough data yet...",
      color: "text-green-600"
    }
  ];

  const secondaryMetrics = [
    {
      title: "Conversions",
      value: formatNumber(data.all_conversions),
      icon: Target,
      detail: hasData(data.all_conversions)
        ? "Total conversions"
        : "No conversions yet",
      color: "text-purple-600"
    },
    {
      title: "CTR",
      value: formatPercentage(data.ctr),
      icon: ArrowUpRight,
      detail: hasData(data.ctr)
        ? "Click-through rate"
        : "No click data yet",
      color: "text-orange-600"
    },
    {
      title: "Cost",
      value: formatCurrency(data.cost_micros),
      icon: DollarSign,
      detail: hasData(data.cost_micros)
        ? "Total spend"
        : "No spend yet",
      color: "text-red-600"
    }
  ];

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-2xl font-bold">Campaign Analytics</h1>
        </div>
        <div className="flex items-center space-x-4">
          <div className="flex flex-col items-end">
            <span className={`px-3 py-1 rounded-full text-sm ${
              data.status === 'ACTIVE' ? 'bg-green-100 text-green-800' : 'bg-yellow-100 text-yellow-800'
            }`}>
              {data.status}
            </span>
          </div>
          <button
            onClick={toggleCampaignStatus}
            className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors"
          >
            {data.status === 'ACTIVE' ? 'Pause Campaign' : 'Activate Campaign'}
          </button>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {metrics.map((metric) => (
          <Card key={metric.title} className="p-6">
            <div className="flex justify-between items-start">
              <div>
                <h3 className="text-lg font-semibold text-gray-700">{metric.title}</h3>
                <p className="text-3xl font-bold mt-2">{metric.value}</p>
                <p className="text-gray-500 mt-2">{metric.detail}</p>
              </div>
              <metric.icon className={`${metric.color} w-6 h-6`} />
            </div>
          </Card>
        ))}
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {secondaryMetrics.map((metric) => (
          <Card key={metric.title} className="p-6">
            <div className="flex justify-between items-start">
              <div>
                <h3 className="text-lg font-semibold text-gray-700">{metric.title}</h3>
                <p className="text-3xl font-bold mt-2">{metric.value}</p>
                <p className="text-gray-500 mt-2">{metric.detail}</p>
              </div>
              <metric.icon className={`${metric.color} w-6 h-6`} />
            </div>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default AnalyticsDashboard;