import React, { act, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCampaign } from '@/contexts/CampaignContext';
import { useAuth } from "@/utils/AuthProvider";
import { ChevronDown, Plus, LogOut, User } from 'lucide-react';
import { cn } from "@/utils/utils";
import { ACTIVE_CAMPAIGN_ID } from '@/types/constants';
import { Campaign } from '@/pages/page_types/campaigns';

export const CampaignNav = () => {
  const { 
    loadingCampaigns,
    campaignStatusLoading,
    campaigns,
    activeCampaign,
    setActiveCampaign,
    setShowOnboarding,
    setActiveCampaignFull,
    hasCampaigns
  } = useCampaign();
  
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { session, signOut } = useAuth();
 
  if (!session || !hasCampaigns || loadingCampaigns || campaignStatusLoading) {
    return null;
  }
 
  const handleCampaignSelect = (campaign: Campaign) => {
    localStorage.setItem(ACTIVE_CAMPAIGN_ID, campaign.id);
    setActiveCampaign(campaign);
    setIsDropdownOpen(false);
    setActiveCampaignFull(campaign);
  };
 
  const activeDomain = activeCampaign?.domain || campaigns[0]?.domain || 'Select Campaign';
 
  return (
    <div className="relative">
      <button
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        className="flex items-center gap-2 px-3 py-2 rounded-md hover:bg-gray-100 transition-colors"
      >
        <span className="text-gray-700">{activeDomain}</span>
        <ChevronDown className="h-4 w-4 text-gray-500" />
      </button>
 
      {isDropdownOpen && (
        <>
          <div
            className="fixed inset-0 z-30"
            onClick={() => setIsDropdownOpen(false)}
          />
          <div className="absolute right-0 mt-2 w-64 bg-white rounded-md shadow-lg border z-40">
            {campaigns.map((campaign) => (
              <button
                key={campaign.id}
                onClick={() => handleCampaignSelect(campaign)}
                className={cn(
                  "flex items-center w-full px-4 py-2 text-left hover:bg-gray-100",
                  campaign.id === activeCampaign?.id && "bg-blue-50"
                )}
              >
                <div className="flex items-center gap-2">
                  <div className="h-4 w-4" />
                  <span>{campaign.domain}</span>
                </div>
              </button>
            ))}
            
            <div className="border-t">
              <button
                onClick={() => {
                  setIsDropdownOpen(false);
                  setShowOnboarding(true);
                }}
                className="flex items-center gap-2 w-full px-4 py-2 text-left hover:bg-gray-100"
              >
                <Plus className="h-4 w-4" />
                <span>Add website</span>
              </button>
              
              {session?.user?.email && (
                <button
                  className="flex items-center gap-2 w-full px-4 py-2 text-left hover:bg-gray-100"
                >
                  <User className="h-4 w-4" />
                  <span>{session.user.email}</span>
                </button>
              )}
              
              <button
                onClick={() => {
                  signOut();
                  setIsDropdownOpen(false);
                }}
                className="flex items-center gap-2 w-full px-4 py-2 text-left hover:bg-gray-100"
              >
                <LogOut className="h-4 w-4" />
                <span>Log out</span>
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
 };