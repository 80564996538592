import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-white text-neutral-900 py-8 px-12 mt-auto border-t border-neutral-200">
      <div className="max-w-[1400px] mx-auto">
        <div className="flex flex-col sm:flex-row justify-between items-center space-y-6 sm:space-y-0">
          {/* Left section */}
          <div className="flex items-center space-x-2">
                <img src="/aperture-thin.png" alt="Multiply" className="h-8" />
            <div>
              <span className="text-xl font-semibold">Multiply</span>
              <p className="text-neutral-600 text-sm">
                Run Google Ads effortlessly with AI
              </p>
            </div>
          </div>

          {/* Middle section - Company links */}
          <div className="flex flex-col sm:flex-row items-center sm:items-baseline space-y-3 sm:space-y-0 sm:space-x-8">
            <a href="#" className="text-neutral-600 hover:text-neutral-900 transition-colors">
              Terms of Use
            </a>
            <a href="#" className="text-neutral-600 hover:text-neutral-900 transition-colors">
              Privacy Policy
            </a>
            <a href="#" className="text-neutral-600 hover:text-neutral-900 transition-colors">
              Contact
            </a>
          </div>

          {/* Right section */}
          <div className="flex items-center space-x-4">
            <img src="/aperture-thin.png" alt="Multiply" className="h-8" />
          </div>
        </div>

        <div className="text-center sm:text-left mt-6 text-sm text-neutral-600">
          © 2025 Grow With Multiply Inc. All right reserved.
        </div>
      </div>
    </footer>
  );
};

export default Footer;