// components/GoogleMapsProvider.tsx
import React from 'react';
import { LoadScript, Libraries } from '@react-google-maps/api';
import { Card, CardContent } from '@/components/ui/card';

const libraries: Libraries = ['places'];

interface GoogleMapsProviderProps {
  children: React.ReactNode;
}

export const GoogleMapsProvider: React.FC<GoogleMapsProviderProps> = React.memo(({ children }) => {
  return (
    <LoadScript
      googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY!}
      libraries={libraries}
      loadingElement={
        <Card className="w-full max-w-3xl mx-auto">
          <CardContent className="p-6">
            <div className="flex items-center justify-center min-h-[400px]">
              <div className="animate-pulse text-gray-500">Loading Maps...</div>
            </div>
          </CardContent>
        </Card>
      }
    >
      {children}
    </LoadScript>
  );
});

GoogleMapsProvider.displayName = 'GoogleMapsProvider';